
@import '../theme-bootstrap';

///
/// @file standalone/mpp.scss
///
/// \brief Styles for multi-product pages
///
///

.mpp__container {
  padding: 30px 10px 20px;
  @include breakpoint($medium-up) {
    padding: 0 40px 50px;
  }
  &--custom {
    margin: 20px 0px;
    @include breakpoint($medium-up) {
      margin-top: 50px;
    }
  }
  &.mpp__remove-margin {
    margin-top: 0px;
  }
  .mpp__category-name {
    display: block;
    text-align: center;
    padding: 0 0 20px;
    @include breakpoint($medium-up) {
      padding: 0 0 4%;
      max-width: 1280px;
      margin-#{$ldirection}: auto;
      margin-#{$rdirection}: auto;
    }
    h2 {
      @include line-behind-text();
      @include h2;
    }
  }
}

@mixin sort-filter-header {
  @include swap_direction(padding, 13px 35px 10px 10px);
  cursor: pointer;
  display: block;
  color: $color-filter-sort-inactive;
  margin-#{$rdirection}: 15px;
  border: 0px;
  text-decoration: none;
  position: relative;
  @include icon('caret--down', $text-replace: false);
  @include font-face--helvetica-medium;
  font-size: 12px;
  @include breakpoint($medium-up) {
    @include swap_direction(padding, 13px 35px 10px 0);
    font-size: 14px;
    margin-#{$rdirection}: 25px;
  }
  & > a {
    border: 0px;
    color: $color-filter-sort-inactive;
  }

  // up/down arrow
  &:before {
    color: $color-filter-sort-inactive;
    font-size: 11px;
    position: absolute;
    display: block;
    #{$rdirection}: 16px;
    top: 21px;
    @include breakpoint($medium-up) {
      #{$rdirection}: 10px;
      top: 23px;
    }
  }
  &.open {
    color: $color-black;
    @include icon('caret--up', $text-replace: false);
    &:before {
      color: $color-black;
    }
  }
}

.mpp-filter-set-formatter {
  display: inline-block;
  .mpp-filter-set {
    &__title {
      @include sort-filter-header;
      .filter-selected {
        display: none;
        .active-filters & {
          @include breakpoint($medium-up) {
            display: inline;
          }
        }
      }
      // don't show while flyout is open
      &.open {
        .filter-selected {
          display: none;
        }
      }
    }
    &__header {
      .mpp-filter-set__reset {
        display: none;
      }
    }
    &__body {
      @include pie-clearfix;
      background: $color-white;
      position: absolute;
      display: none;
      z-index: 3;
      #{$ldirection}: 0;
      min-width: 300px;
      @include breakpoint($medium-up) {
        padding: 50px;
        padding-top: 20px;
        min-width: 700px;
        #{$rdirection}: 20px;
        #{$ldirection}: auto;
      }
    }
    &__category-wrapper {
      @include pie-clearfix;
      padding-#{$rdirection}: 10px;
      @include breakpoint($medium-up) {
        padding-#{$rdirection}: 0;
      }
    }
    &__category {
      font-size: 12px;
      @include breakpoint($medium-up) {
        float: #{$ldirection};
      }
      &--PROD_RGN_NAME {
        @include breakpoint($medium-up) {
          float: #{$rdirection};
        }
      }
      &-links {
        margin-bottom: 15px;
        @include breakpoint($medium-up) {
          margin-bottom: 0px;
        }
      }
      &-name {
        @include font-face--helvetica-medium;
        text-transform: uppercase;
        border-bottom: 1px solid $color-filter-menu-item-border;
        display: block;
        height: 34px;
        line-height: 37px;
        vertical-align: middle;
        padding-#{$ldirection}: 15px;
        font-size: 11px;
        @include breakpoint($medium-up) {
          line-height: 38px;
          font-size: 12px;
          border: none;
          margin-top: 0px;
          margin-bottom: 10px;
          padding-#{$ldirection}: 0;
        }
      }
    }
    &__link {
      display: block;
      @include font-face--helvetica-roman;
      color: $color-black;
      display: block;
      border: none;
      height: 34px;
      line-height: 37px;
      vertical-align: middle;
      position: relative;
      padding-#{$rdirection}: 25px;
      padding-#{$ldirection}: 15px;
      border-bottom: 1px solid $color-filter-menu-item-border;
      font-size: 12px;
      @include breakpoint($medium-up) {
        line-height: 38px;
        color: $color-black;
        font-size: 14px;
        padding-#{$ldirection}: 25px;
        padding-#{$rdirection}: 0;
        border: none;
      }
      &:before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border: 1px solid $color-filter-sort-inactive;
        position: absolute;
        top: 50%;
        transform: translate(0%, -50%) rotate(45deg); /* Equal to rotateZ(45deg) */
        transition: all 0.5s;
        #{$rdirection}: 10px;
        @include breakpoint($medium-up) {
          #{$ldirection}: 0;
          #{$rdirection}: auto;
        }
      }
      &.active {
        color: $color-black;
        &:before {
          background: $color-black;
          border-color: $color-black;
          transform: translate(0%, -50%) rotate(135deg); /* Equal to rotateZ(45deg) */
        }
      }
    }
    &__close {
      display: none;
    }
    &__footer {
      clear: both;
      padding: 30px 15px;
      @include breakpoint($medium-up) {
        padding: 30px 0px;
        margin-top: 40px;
        border-top: 1px solid $color-button-light-gray;
      }
    }
    &__submit {
      display: none;
    }
    &__reset {
      @include button;
      @include button-secondary;
      width: 100%;
      @include breakpoint($medium-up) {
        float: #{$rdirection};
        width: auto;
      }
    }
  }
}

.mpp-sort__container {
  @include breakpoint($medium-up) {
    position: relative;
  }
  .mpp-sort__label {
    @include sort-filter-header;
  }
  .mpp-sort__content {
    background: $color-white;
    position: absolute;
    z-index: 1;
    display: none;
    #{$ldirection}: 0;
    min-width: 300px;
    @include breakpoint($medium-up) {
      min-width: auto;
      padding: 30px;
      padding-top: 20px;
      #{$rdirection}: 10px;
      #{$ldirection}: auto;
    }

    // show below menu item when there's the clear all
    .active-filters & {
      @include breakpoint($medium-up) {
        #{$rdirection}: 50%;
        transform: translate(50%, 0%);
      }
    }
    li {
      @include font-face--helvetica-roman;
      font-size: 12px;
      height: 35px;
      line-height: 35px;
      vertical-align: middle;
      cursor: pointer;
      white-space: nowrap;
      padding-#{$ldirection}: 15px;
      border-bottom: 1px solid $color-filter-menu-item-border;
      color: $color-filter-sort-inactive;
      @include breakpoint($medium-up) {
        font-size: 14px;
        color: $color-filter-sort-inactive;
        border: none;
        text-align: #{$rdirection};
      }
      &.active {
        color: $color-black;
      }
    }
  }
}

.mpp_custom_product_layouts-formatter {
  .mpp__container {
    margin-top: 0px;
    padding-top: 50px;
  }
  .product-breadcrumb.product-breadcrumb--custom {
    float: #{$ldirection};
  }
  &__utility {
    position: relative;
    @include pie-clearfix;
    height: 50px;
    width: 100%;
    @include breakpoint($medium-up) {
      max-width: 1280px;
      margin-#{$rdirection}: auto;
      margin-#{$ldirection}: auto;
    }
    &__filter-sort {
      @include breakpoint($medium-up) {
        float: #{$rdirection};
        .contextual-links-region {
          position: static;
        }
      }
    }
    &__filter-node {
      float: #{$ldirection};
    }
    .mpp-sort__container {
      float: #{$ldirection};
    }
    .mpp_custom_product_layouts-formatter__utility__reset {
      float: #{$rdirection};
      @include sort-filter-header;
      @include icon('close', $text-replace: false);
      a {
        display: none;
        @include breakpoint($medium-up) {
          display: inline;
        }
      }
      &:before {
        top: 19px;
        #{$rdirection}: 13px;
        font-weight: bold;
        font-size: 10px;
      }
      display: none;
      .active-filter-sort & {
        display: block;
      }
    }
    .product-breadcrumb.product-breadcrumb-custom {
      padding: 0px;
    }
  }
  &.mpp-filter-sort-open {
    .mpp_custom_product_layouts-formatter__content,
    .mpp_custom_product_layouts-formatter__content--all {
      position: relative;
      &:after {
        content: '';
        opacity: 0.5;
        background: $color-black;
        position: absolute;
        top: 0px;
        #{$ldirection}: 0;
        width: 100%;
        height: calc(100% + 20px); // account for bottom margin
      }
    }
  }
  .mpp_custom_product_layouts-formatter__content--all {
    opacity: 0;
    height: 0px;
    overflow: hidden;
    clear: both;
    min-height: auto !important;
  }
  &.sort-all {
    &.active-filter-sort {
      .mpp_custom_product_layouts-formatter__content {
        display: none;
      }
      .mpp_custom_product_layouts-formatter__content--all {
        display: block;
        height: auto;
        min-height: unset !important;
        opacity: 1;
      }
    }
  }
}
