// This file is generated by `gulp icons`, do not modify

$icons: (
  accessibility: '\EA01',
  account: '\EA02',
  bag: '\EA03',
  caret--down: '\EA04',
  caret--left: '\EA05',
  caret--right: '\EA06',
  caret--up: '\EA07',
  checkbox--checked: '\EA08',
  checkbox: '\EA09',
  circle-caret--left: '\EA0A',
  circle-caret--right: '\EA0B',
  close: '\EA0C',
  email: '\EA0D',
  facebook: '\EA0E',
  fullscreen: '\EA0F',
  hamburger: '\EA10',
  head--outline: '\EA11',
  head--solid: '\EA12',
  heart--filled: '\EA13',
  heart--outline: '\EA14',
  instagram: '\EA15',
  livechat: '\EA16',
  location--filled: '\EA17',
  location: '\EA18',
  logo: '\EA19',
  map-marker: '\EA1A',
  minus: '\EA1B',
  phone: '\EA1C',
  pinterest--circle: '\EA1D',
  pinterest: '\EA1E',
  play: '\EA1F',
  plus: '\EA20',
  quill: '\EA21',
  radio--checked: '\EA22',
  radio: '\EA23',
  search: '\EA24',
  share: '\EA25',
  stars: '\EA26',
  twitter: '\EA27',
  youtube--play: '\EA28',
  youtube: '\EA29'
);
